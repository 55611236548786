import { defineStore } from 'pinia'
import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts'
import { Program, User as UserModel } from '@/models/index'
import {loginWithLyfpay, loginWithPcs, fetchBalance} from '@/api/users'
import { getSubdomain } from '@/utils/subdomain-handler'
import ApplicationRecord from '@/models/ApplicationRecord'

import router from '@/router'

import {
  setToken,
  setExternalToken,
  getToken,
  getExternalToken,
  removeToken,
  removeExternalToken
} from '@/utils/local-storage'

const CLIENT_IDS: any = {
  app: "x8Wt3oFztJIWlJe3zN8XUdN00zFPFnuAVB6Yr0a_CWY",
  my: "NZ4auKmKa4VaedcUC-JFkYdD5P5ZgIPEjMA4uPkPS1g",
  happyprime: "bPBxS9eZEoeX4aI-bfEnwwXwQtyTuLwOP5fUIkr9Mr8",
  'cfe-cgc': "0KQ3F4Z-87Cu_SdFlrXWAYmHXMvCNcYY3ty4BicNvZI",
  pcs: "RTH8sAEmk_tsNrbgUg5nl9f07tZapKhnNSeKpSombCg",
  groupama: "_CSXjbqcH37MtMu_tmRV_iqhoSrAVMHQStXlr4MeDw4",
  bemove: "2L2y6RDHDuKQlPFyUk5RqbmvKR6X8s9NJyK1X_9wRBY",
  avantagesieg: "8AMAQjlzsMn95iBdsdfV1_KFgw8v6ZpO46-mPPXcNq8",
  maif: "_H7GkLCL6LBf3rJzCbuyd9FYsTGyAJJQPI6CUeHyqhI"
}

function getClientID() {
  let clientId = null

  if (process.env.VUE_APP_ENV === 'production') {
    const subdomain = getSubdomain()

    clientId = CLIENT_IDS[subdomain] || CLIENT_IDS.app
  } else
    clientId = process.env.VUE_APP_CLIENT_ID

  return clientId
}

function removeQueryParams(url: string, params: string[]): string {
  const urlObj = new URL(url, window.location.origin)
  params.forEach(param => urlObj.searchParams.delete(param))
  return `${urlObj.pathname}${urlObj.search}`
}

function getRedirectURI() {
  let redirectOauth = ''
  redirectOauth = removeQueryParams(window.location.href, ['code', 'state'])
  return `${window.location.origin}${redirectOauth && window.location.pathname !== '/' ? `?redirect=${encodeURIComponent(redirectOauth)}` : ''}`
}

interface State {
  userManager: UserManager
  user: User | any
  model: UserModel
  modelLoading: boolean
  token: string
  externalToken: string
  tokenExpiresIn: number
  optInRefused: boolean
  lyfEnv: string | null
  lastSavConversation: string | null
  discountsRefused: boolean
  balance: any
  balanceLoading: boolean
}

export const useUserStore = defineStore('user', {
  state: (): State => ({
    userManager: new UserManager({
      userStore: new WebStorageStateStore({ store: localStorage }), // Should be store in Memory
      authority: process.env.VUE_APP_OPENID_SERVER_URL!,
      client_id: getClientID()!,
      redirect_uri: getRedirectURI()!,
      silent_redirect_uri: getRedirectURI(),
      post_logout_redirect_uri: getRedirectURI(),
      automaticSilentRenew: false,
      response_type: 'code',
      scope: 'openid profile'
    }),
    user: null,
    model: new UserModel({ program: new Program() }),
    modelLoading: false,
    token: getToken() || '',
    externalToken: getExternalToken() || '',
    tokenExpiresIn: -1,
    optInRefused: false,
    lyfEnv: null, // Maybe we can do more clean
    lastSavConversation: null,
    discountsRefused: false,
    balance: null,
    balanceLoading: false
  }),
  getters: {
    isLyfPayCustomer: (state) => state?.model?.program?.name === 'Lyfpay',
    isBemoveCustomer: (state) => state?.model?.program?.name === 'Bemove Avantages',
    isAvantagesIEGCustomer: (state) => state?.model?.program?.name === 'Avantages IEG',
    isMaifAvantagesCustomer: (state) => state?.model?.program?.name === 'Maif Avantages',
    isPcsCustomer: (state) => (state?.model?.program?.name === 'PCS' || state?.model?.program?.name === 'PCS PRO'),
    isPcsProCustomerOnly: (state) => state?.model?.program?.name === 'PCS PRO',
    savConversation: (state) => state.lastSavConversation,
    multiChoiceGiftCardBrandName: (state) => state.model.program.multiChoiceGiftCardBrandName,
    paymentAndBalanceName: (state) => state.model.program.balanceName,
    accessToken: (state) => state?.user?.access_token || state.token
  },
  actions: {
    signinRedirect(): Promise<void> {
      return this.userManager.signinRedirect()
    },
    async signinRedirectCallback(): Promise<void> {
      await this.userManager.signinRedirectCallback()
        .then((user) => {
          this.user = user
          ApplicationRecord.jwtStorage = false
          ApplicationRecord.jwt = this.user.access_token
        })
        .catch(() => {
        router.push({ name: 'TokenExpired' }).catch(err => err)
      })
    },
    async signinSilent(): Promise<User|null> {
      await this.userManager.signinSilentCallback()

      this.user = await this.userManager.signinSilent()
      if (this.user) {
        ApplicationRecord.jwtStorage = false
        ApplicationRecord.jwt = this.user.access_token
      }

      return this.user
    },
    async loginWithLyfpay(jwt: string) {
      await this.clearUser()
      await this.resetToken()

      const body = { jwt: jwt }

      try {
        const data: any = await loginWithLyfpay(body)

        setExternalToken(jwt)
        this.externalToken = jwt

        setToken(data.access_token)
        this.token = data.access_token

        ApplicationRecord.jwtStorage = false
        ApplicationRecord.jwt = data.access_token

        this.optInRefused = false
        this.tokenExpiresIn = data.expires_in
        this.lyfEnv = data.lyf_env
      } catch(err: any) {
        const errors = err.response.data.errors
        const optInRefused = errors.map((e: any) => e.code).includes('opt_in_refused')
        this.optInRefused = optInRefused
      }
    },
    async loginWithPcs(jwt: string) {
      await this.clearUser()
      await this.resetToken()

      const body = { jwt: jwt }

      try {
        const data: any = await loginWithPcs(body)

        setExternalToken(jwt)
        this.externalToken = jwt

        setToken(data.access_token)
        this.token = data.access_token

        ApplicationRecord.jwtStorage = false
        ApplicationRecord.jwt = data.access_token

        this.tokenExpiresIn = data.expires_in
      } catch(err: any) {
        const errors = err.response.data.errors
      }
    },
    async getUser(): Promise<User|null> {
      this.user = await this.userManager.getUser()

      return this.user
    },
    async fetchBalance() {
      this.balanceLoading = true

      const data: any = await fetchBalance(this.externalToken)
      this.balance = data["Balance"]

      this.balanceLoading = false
    },
    async getModelUser() {
      this.modelLoading = true

      const { data } = await UserModel.includes('program').find('')
      if (!data) throw Error('Verification failed, please Login again.')

      this.model = data
      this.modelLoading = false
    },

    async setEarnDiscountsStatus(value: boolean) {
      this.model.acceptEarnDiscounts = value || false
      await (this.model as UserModel).save()
    },
    async logOut(signinRedirect = false, withOauth = false) {
      await this.userManager.revokeTokens()
      await this.userManager.removeUser()
      localStorage.removeItem('jwt')

      const queryParams = signinRedirect ? { sign_in_redirect: 'true' } : {}
      if (withOauth) await this.userManager.signoutRedirect()
      await router.push({ name: 'Login', query: queryParams })
    },
    setDiscountsRefused(value: boolean) {
      this.discountsRefused = value
    },
    setNewSavConversation(groupId: string) {
      this.lastSavConversation = groupId
    },
    resetToken() {
      removeToken()
      removeExternalToken()

      this.token = ''
      this.externalToken = ''
    },
    clearUser () {
      this.$reset()
    }
  },
  persist: {
    paths: ['optInRefused', 'lyfEnv', 'lastSavConversation', 'discountsRefused', 'model.program.primaryColor', 'model.program.secondaryColor']
  }
})
